import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import translator from '../../translator';
import { ReactComponent as KSLogo } from '../assets/images/svg/ks-logo.svg'

export default function ButtonAppBar() {
  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {translator('header.text')}
              <span className='yellowColor'> 2025</span>
          </Typography>
          <KSLogo style={{height: '35px'}} /> 
        </Toolbar>
      </AppBar>
    </Box>
  );
}
